import React, { useState } from "react";
import { UserDetail } from "../../models/UserDetail";
import { Company, Depot } from "../../models/Company";
import { ChangeEventArgs, CheckBoxComponent } from "@syncfusion/ej2-react-buttons";


export interface AddUserModalProps {
	type: string;
	currentUser: UserDetail;
	changeHandler: (name: string, value: string | boolean) => void;
	companies: Company[];
	closeModals: () => void;
}


const UserAddEdit = (props: AddUserModalProps) => {
	const [companies, setCompanies] = useState<{ value: string; text: string }[]>([]);
	const [depots, setDepots] = useState<{ value: string; text: string }[]>([]);

	React.useEffect(() => {
		const companyOptions = () => {
			return props.companies?.map((company: Company) => {
				return {
					value: company.companyId,
					text: company.companyName,
				};
			});
		};

		setCompanies(companyOptions());

		if (props.currentUser.defaultcompanyid !== undefined) {
			props.currentUser.defaultCompanyId = props.currentUser.defaultcompanyid;
		}

		if (props.currentUser.defaultdepotid !== undefined) {
			props.currentUser.defaultDepotId = props.currentUser.defaultdepotid;
		}
		
	}, [props.currentUser]);

	React.useEffect(() => {
		if (props.currentUser.defaultcompanyid === undefined && props.currentUser.defaultCompanyId === undefined || props.companies === undefined) {return;}
		let currentCompany: Company = props.companies.find(c => c.companyId === props.currentUser.defaultcompanyid)!
		if (currentCompany === undefined) {
			currentCompany = props.companies.find(c => c.companyId === props.currentUser.defaultCompanyId)!
		}
		if (currentCompany === undefined || currentCompany.depots === undefined) {return;}

		setDepots(currentCompany.depots.map((d: Depot) => {
			return {
				value: d.id,
				text: d.name
			}
		}));
	}, [companies, props.companies, props.currentUser])

	const checkboxChangeLocked = (e: React.ChangeEvent<ChangeEventArgs>) => {
		props.changeHandler('Locked', e.checked);
	}

	const checkboxChangeEnabled = (e: React.ChangeEvent<ChangeEventArgs>) => {
		props.changeHandler('Enabled', e.checked);
	}

	const handleChange = (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
			| React.ChangeEvent<HTMLSelectElement>
	) => {

		const { name, value, type } = e.target;
		props.changeHandler(name, value);

		if (name === "DefaultCompanyId")
		{
			const company: Company = props.companies.find(e => e.companyId === value)!;
			setDepots(company.depots.map((d: Depot) => {
				return {
					value: d.id,
					text: d.name
				}
			}));
		}

	};
	
	return (
		<div id="showUserDiv">
			<div className="inputDiv mb-3">
				<label htmlFor="UserName" className="fw-bold">Username</label>
				<input
					type="text"
					id={'username' + props.type}
					name="UserName"
					className="form-control"
					placeholder="Username"
					value={props.currentUser.username}
					onChange={handleChange}
				/>
			</div>
			<div className="inputDiv mb-3">
				<label htmlFor="DefaultCompanyId" className="fw-bold">Default Company</label>
				<select
					id="DefaultCompanyId"
					name="DefaultCompanyId"
					value={props.currentUser.defaultCompanyId}
					onChange={handleChange}
					className="form-control"
				>
					<option value=''>Select Company</option>
					{companies?.map((option, key) => {
						return (
							<option key={key} value={option.value}>
								{option.text}
							</option>
						);
					})}
				</select>
			</div>
			<div className="inputDiv mb-3">
				<label htmlFor="DefaultDepotId" className="fw-bold">Default Depot</label>
				<select
					id="DefaultDepotId"
					name="DefaultDepotId"
					value={props.currentUser.defaultDepotId}
					onChange={handleChange}
					className="form-control"
				>
					<option value=''>Select Depot</option>
					{depots?.map((option, key) => {
						return (
							<option key={key} value={option.value}>
								{option.text}
							</option>
						);
					})}
				</select>
			</div>
			<div className="inputDiv mb-3">
				<label htmlFor="Email" className="fw-bold">Email</label>
				<input
					type="email"
					id={'email' + props.type}
					name="Email"
					title=""
					placeholder="Email"
					value={props.currentUser.email}
					onChange={handleChange}
					className="form-control"
				/>
			</div>
			
			{props.type === "edit" ? (
				<div>
					<CheckBoxComponent
						type="checkbox"
						id="Enabled"
						name="Enabled"
						label="Active User"
						checked={props.currentUser.enabled}
						change={checkboxChangeEnabled}
						/>
<br />
					<CheckBoxComponent
						type="checkbox"
						id="Locked"
						name="Locked"
						label="Locked User"
						checked={props.currentUser.locked}
						change={checkboxChangeLocked}
					/>
				</div>
			) : (<></>)}
			
			
		</div>
	);
};

export default UserAddEdit;
