import React, { useState, useEffect } from "react";
import { MessageComponent } from '@syncfusion/ej2-react-notifications';

interface NewPasswordProps {
    onValidationChange: (password: string, isValid: boolean) => void;
}

export const NewPassword: React.FC<NewPasswordProps> = ({onValidationChange}) => {

	const [newPassMessage, setNewPassMessage] = useState<string[]>([]);
	const [password, setPassword] = useState<string>('');
	const [repeatPassword, setRepeatPassword] = useState<string>('');

    function newPasswordChanged(value: string) {
        setPassword(value);
        updatePasswordErrorMessage(value, repeatPassword);
    }

    function repeatPasswordChanged(value: string) {
        setRepeatPassword(value)
        updatePasswordErrorMessage(password, value);
    }

    function updatePasswordErrorMessage(newPass: string, repeatPass: string){
        const errorMessages: string[] = [];

        if (newPass !== repeatPass) {
            errorMessages.push('Your passwords do not match.')
        }
        if ((process.env.REACT_APP_PASSWORD_COMPLEXITY_REQUIRE_NUM === 'true' && !/(?=.*\d)/.test(newPass))) {
            errorMessages.push("You must include a number.");
        }
        if ((process.env.REACT_APP_PASSWORD_COMPLEXITY_REQUIRE_UPPER === 'true' && !/(?=.*[A-Z])/.test(newPass))) {
            errorMessages.push("You must include a capital letter.");
        }
        if ((process.env.REACT_APP_PASSWORD_COMPLEXITY_REQUIRE_LOWER === 'true' && !/(?=.*[a-z])/.test(newPass))) {
            errorMessages.push("You must include a lower-case letter.");
        }
        if ((process.env.REACT_APP_PASSWORD_COMPLEXITY_REQUIRE_SPECIAL === 'true' && !/(?=.*[!@#$%^&*])/.test(newPass))) {
            errorMessages.push("You must include a special character.");
        }
        if (newPass.length < Number(process.env.REACT_APP_PASSWORD_COMPLEXITY_MIN_CHARS)) {
            errorMessages.push("Password must be at least " + process.env.REACT_APP_PASSWORD_COMPLEXITY_MIN_CHARS + " characters.");
        }
        if (newPass.length > 64) {
            errorMessages.push("Password must be no longer than 64 characters.");
        }

        setNewPassMessage(errorMessages);
        onValidationChange(newPass, errorMessages.length === 0 && newPass.length > 0);
    }

    const ShowErrors = () => {
        return (
            <span>
                <strong>Password validation:</strong>
                <ul>
                    {newPassMessage.map((err, index) => (
                        <li key={index}>{err}</li>
                    ))}
                </ul>
            </span>
        );
    }

    return (
        <div>
            <div className="inputDiv mb-3">
                <label htmlFor="password" className="fw-bold">Password</label>
                <input
                    type="password"
                    id="password1"
                    autoComplete="new-password"
                    placeholder="New Password"
                    onChange={e => {newPasswordChanged(e.target.value);}}
                    className="form-control"
                />
            </div>
            <div className="inputDiv mb-3">
                <label htmlFor="confirmPassword" className="fw-bold">Repeat Password</label>
                <input
                    type="password"
                    id="confirmPassword1"
                    autoComplete="new-password"
                    placeholder="Confirm New Password"
                    onChange={e => {repeatPasswordChanged(e.target.value);}}
                    className="form-control"
                />
            </div>

            {newPassMessage?.length > 0 && (
                    <MessageComponent severity="Error" content={ShowErrors} />
            )}
        </div>
    );
}

export default NewPassword;