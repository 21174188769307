/* eslint-disable import/no-anonymous-default-export */
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default {
  /**
   * Returns the value of the specified key from cookies
   * @param {*} key
   */
  get(key) {
    if (this.any(key)) {
      return cookies.get(key);
    } else {
      return "";
    }
  },

  /**
   * Store the value of the specified key in cookies and
   * set expireTime
   * @param {*} key
   * @param {*} value
   * @param {*} expireTime
   */
  set(key, value, expireTime = 9) {
    /* Remove existing to avoid duplicate */
    if (this.any(key)) {
      this.remove(key);
    }
    const cookieExpiry = new Date(Date.now() + expireTime * 86400000);
    cookies.set(key, JSON.stringify(value), {
      path: "/",
      expires: cookieExpiry,
    });
  },

  /**
   * Delete the value of the specified key from cookies.
   * @param {*} key
   */
  remove(key) {
    if (this.any(key)) {
      cookies.remove(key, { path: "/" });
    }
  },
  /**
   * check a cookie exist.
   * @param {*} key
   */
  any(key) {
    let item = cookies.get(key);
    return item !== "";
  },
};
