import axios from 'axios';
import { userManager } from './user-service';

const instance = axios.create();

const ApiService = {
    init() {
        instance.defaults.baseURL = 
            process.env.REACT_APP_BASE_URI +
            process.env.REACT_APP_SARM_URL +
            '/'
        instance.defaults.headers.common['Cache-Control'] = 'no-cache';
    },

    async getUserRequestHeaders() {

        var user = await userManager.getUser();
        
        if (user === null || user.expired) {
            await userManager.signinSilent();
            user = await userManager.getUser();
        }        
        if (user === null || user.expired) {
            await userManager.signinSilent();
            user = await userManager.getUser();
        }

        const accessToken = user.access_token;
        const headers = {
            Authorization: `Bearer ${accessToken}`
        };

        return headers;
    },

    async makeApiRequest(url, method, data) {
        const headers = await this.getUserRequestHeaders();
        return await instance.request({
            url,
            method,
            headers,
            data
        });
    },

    async makeApiRequestNoHeaders(url, method, data) {
        return await instance.request({
            url,
            method,
            headers: { "Content-Type": "multipart/form-data" },
            data
        });
    },

    get(url) {
        return this.makeApiRequest(url, 'GET');
    },

    post(url, data) {
        return this.makeApiRequest(url, 'POST', data);
    },

    postNoHeaders(url, data) {
        return this.makeApiRequestNoHeaders(url, 'POST', data);
    },

    put(url, data) {
        return this.makeApiRequest(url, 'PUT', data);
    },

    patch(url, data) {
        return this.makeApiRequest(url, 'PATCH', data);
    },

    httpDelete(url, data) {
        return this.makeApiRequest(url, 'DELETE', data);
    }
};

export default ApiService;