import { FC } from "react";
import "./OkCancelDialog.css";
import { DialogComponent } from "@syncfusion/ej2-react-popups";

interface OkCancelDialogProps {
	showDialog: boolean;
	dialogTitle: string;
	dialogMessage: string | Element;
	onOkClick: () => void;
	onCancelClick: () => void;
}

const OkCancelDialog: FC<OkCancelDialogProps> = (
	dialogProps: OkCancelDialogProps
) => {

	var buttons = [
		{
			click: () => {
				dialogProps.onCancelClick();
			},
			buttonModel: {
				content: 'Cancel',
				cssClass: 'btn-outline-theme btn px-4', //primary
			},
		},
		{
			click: () => {
				dialogProps.onOkClick();
			},
			buttonModel: {
				content: 'Ok',
				cssClass: 'btn-theme btn px-4', //primary
			},
		},
	];

	return (
	<DialogComponent 
		width="500px"
		visible={dialogProps.showDialog}
		header={dialogProps.dialogTitle}
		content={dialogProps.dialogMessage}
		buttons={buttons}
		zIndex={950}
		/>
		
)};

export default OkCancelDialog;