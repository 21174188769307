import React, { FC, useState } from "react";
import "./RoleAddEdit.css";
import { Role } from "../../../models/Role";
// import RoleUserEdit from "../RoleUserEdit/RoleUserEdit";
// import RolePermissionEdit from "../RolePermissionEdit/RolePermissionEdit";
import { Company } from "../../../models/Company";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { rolePermissionApi } from "../../../api/role-permission-management";
import OkCancelDialog from "../../Common/OkCancelDialog/OkCancelDialog";
import Loading from "../../../../../common/loading";
import { DialogComponent } from "@syncfusion/ej2-react-popups";

export interface RoleAddEditProps {
	currentRole: Role;
	changeHandler: (name: string, value: string | boolean) => void;
}

const RoleAddEdit: FC<RoleAddEditProps> = (
	{
	currentRole,
	changeHandler,
}) => {
	const [showOkCancelDialog, setShowOkCancelDialog] = useState<boolean>(false);
	const [showSpinner, setShowSpinner] = useState<boolean>(false);
	const handleInputChange = (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		const { name, value, type } = e.target;
		const inputValue =
			type === "checkbox" ? (e.target as HTMLInputElement).checked : value;
		changeHandler(name, inputValue);
	};
	async function beginCopy() {
		setShowSpinner(true);
		await rolePermissionApi.copyRole(currentRole!.id!);
		setShowSpinner(false);
		setShowOkCancelDialog(false);
		window.location.reload();
	}
	return (
		<div className="RoleAddEdit">
			{currentRole.isGlobal && 
				<>
					<i style={{color: '#C12843'}}>This is a default role. It cannot be modified unless you create a copy. You can use the button below to do this.</i>
					<br />
					<button
						type="button"
						className="btn btn-theme"
						id="btnAddRole"
						style={{margin: '16px 0 16px 0'}}
						onClick={() => setShowOkCancelDialog(true)}
					>
						Copy Role
					</button>
				</>
			}
			<div className="mb-3">
				<label htmlFor="txtRoleName" className="fw-bold">Role name</label>
				<input
					type="text"
					id="txtRoleName"
					name="name"
					className="form-control"
					placeholder="Role name here..."
					value={currentRole.name}
					onChange={handleInputChange}
					disabled={currentRole.isGlobal}
				/>
			</div>
			<div className="mb-3">
				<label htmlFor="taRoleDescription" className="fw-bold">Description</label>
				<textarea
					id="taRoleDescription"
					name="description"
					className="form-control"
					placeholder="Description for the role here..."
					value={currentRole.description}
					onChange={handleInputChange}
					rows={6}
					disabled={currentRole.isGlobal}
				/>
			</div>
			
			<CheckBoxComponent
				id="chkRoleActive"
				name="active"
				label="Active"
				type="checkbox"
				checked={currentRole.active}
				onChange={handleInputChange}
				disabled={currentRole.isGlobal}
			/>

			<OkCancelDialog
				showDialog={showOkCancelDialog}
				dialogTitle="Copy Role?"
				dialogMessage="This action will result in the following: <ul><li>Copy Role</li><li>Copy Permissions</li><li>Move Users to the new Role (Where already linked)</li><li>Hide the Default Role</li></ul><br />This action cannot be undone."
				onOkClick={() => beginCopy()}
				onCancelClick={() => {
					setShowOkCancelDialog(false);
				}}
			></OkCancelDialog>

			<DialogComponent header="Copy in Progress..." visible={showSpinner} width="100px" zIndex={999}>
				<Loading />
				Please wait, The page will reload when completed.
			</DialogComponent>
			
		</div>
	);
};

export default RoleAddEdit;
