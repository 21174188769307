import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit'
import { AuthSlice } from "./modules/AuthSlice";
import { UserSlice } from './modules/UserSlice';
import { RoleSlice } from './modules/RoleSlice';


const rootReducer = combineReducers(
    {
        AuthReducer: AuthSlice.reducer,
        UserReducer: UserSlice.reducer,
        RoleReducer: RoleSlice.reducer,
    });

export const store = configureStore({
    reducer: {
        RootReducer: rootReducer
    },
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

