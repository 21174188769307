import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userAPI } from '../../modules/portal/api/user-management'
import { type RootState } from "../index";
import { UserDetail, UserPassword } from "../../modules/portal/models/UserDetail";
export interface Users {
    search: {
        list: UserDetail[];
    };
}

const initialState: Users = {
    search: {
        list: []
    },
};


export const UserSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        SET_USERS: (state, action) => {
            state.search.list = action.payload.usersList;
        }
    },
});

/* Getters */
export const getUsers = (state: RootState) => {
    return state.RootReducer.UserReducer.search.list;
}
/* actions */

export const getUsersList = createAsyncThunk(
    "user/list",
    async (args: string, { dispatch }) => {
        try {
            const response = await userAPI.getUsers();
            const usersList: UserDetail[] = response.data.data;
            dispatch(SET_USERS({ usersList }));
            return usersList;
        } catch (error: any) {
            console.error(error);
        }
    }
);

export const addUser = createAsyncThunk(
    "user/Add",
    async (args: UserDetail, { dispatch }) => {
        try {
            const response = await userAPI.addUser(args);
            return response.data;
        } catch (error: any) {
            return error.response;
        }
    }
);
export const editUser = createAsyncThunk(
    "user/Update",
    async (args: UserDetail, { dispatch }) => {
        try {
            const response = await userAPI.editUser(args);
            return response.data;
        } catch (error: any) {
            console.error(error);
        }
    }
);

export const deleteUser = createAsyncThunk(
    "user/Delete",
    async (args: string, { dispatch }) => {
        try {
            const response = await userAPI.deleteUser(args);
            return response.data;
        } catch (error: any) {
            console.error(error);
        }
    }
);

export const updateUserPassword = createAsyncThunk(
    "user/AdminUpdateUserPassword",
    async (args: UserPassword, { dispatch }) => {
        try {
            const response = await userAPI.updatePassword(args);
            return response.data;
        } catch (error: any) {
            console.error(error);
        }
    }
);

export const validateAccount = createAsyncThunk(
    "user/account-verify",
    async (args: UserValidate, { dispatch }) => {
        try {
            const response = await userAPI.validateEmail(args);
            return response.data;
        } catch (error: any) {
            console.error(error);
        }
    }
);

export const activateAccount = createAsyncThunk(
    "user/account-activate",
    async (args: UserActivate, { dispatch }) => {
        try {
            const response = await userAPI.activateAccount(args);
            return response.data;
        } catch (error: any) {
            console.error(error);
        }
    }
);

export const resendInvite = createAsyncThunk(
    "user/resend-invitation",
    async (args:string, { dispatch }) => {
        try {
            const response = await userAPI.resendInvitation(args);
            return response.data;
        } catch (error: any) {
            console.error(error);
        }
    }
);

export const {
    SET_USERS,
} = UserSlice.actions;

export default UserSlice.reducer;
