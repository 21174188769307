import { enableRipple } from "@syncfusion/ej2-base";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import React, { FC, useEffect, useState } from "react";
import { rolePermissionApi } from "../../../api/role-permission-management";
import ApiService from "../../../../../common/api-service";
import { RolePermissionLink } from "../../../models/RolePermissionLink";
import { toast } from "react-toastify";
import { Role } from "../../../models/Role";
import { ResponseStatus } from "../../../models/Enum";
import OkCancelDialog from "../../Common/OkCancelDialog/OkCancelDialog";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import Loading from '../../../../../common/loading';
enableRipple(true);

export interface RolePermissionEditProps {
	currentRole: Role | undefined;
}


const RolePermissionEdit: FC<RolePermissionEditProps> = ({ currentRole }) => {
	const [showOkCancelDialog, setShowOkCancelDialog] = useState<boolean>(false);
	const [showSpinner, setShowSpinner] = useState<boolean>(false);
	useEffect(() => {
		const fetchLinks = async () => {
			try {
				if (!currentRole || !currentRole.id || currentRole.id === "undefined") {
					return;
				}

				const response = await rolePermissionApi.getRolePermissions(
					currentRole.id
				);

				if (response.data && response.data.responseStatus === ResponseStatus.Success) {
					let actualResponse = response.data.data;
					let newData: object = {
						dataSource: actualResponse,
						id: "id",
						text: "name",
						child: "children",
						isChecked: "selected",
					};
					setTreeFields(newData);
				} else {
					toast.error(
						"Unable to fetch Permissions against the role. " +
							response.data.message
					);
					console.error(
						"Unable to fetch Permissions against the role. " +
							response.data.message
					);
				}
			} catch (error: any) {
				toast.error("Unable to fetch Permissions against the role." + error);
				console.error("Unable to fetch Permissions against the role." + error);
			}
		};

		fetchLinks();
	}, [currentRole]);

	const [treeFields, setTreeFields] = React.useState<Object>();

	const nodeChecked = async (args: any) => {
		let checkedNode: string | Element | any = args.data[0];
		if (checkedNode.hasChildren === true) {
			for (let i = 0; i < args.data.length; i++) {
				if (i == 0) {
					continue;
				} //ignore parent
				if (args.data[i].hasChildren == true) {
					continue;
				} //ignore groups, get permissions only
				await updateNode(args.data[i]);
			}
		} else {
			await updateNode(checkedNode);
		}
	};

	async function updateNode(targetNode: any) {
		if (!currentRole) {
			return;
		}
		let selectedRoleId: string | undefined = currentRole.id;

		const linkToAdd: RolePermissionLink = {
			permissionId: targetNode.id,
			roleId: selectedRoleId,
		};

		if (targetNode.isChecked === "true") {
			try {
				const response = await rolePermissionApi.addRolePermission(linkToAdd);
				if (response.data && response.data.responseStatus === ResponseStatus.Success) {
					toast.success("Added Permission to Role");
				} else {
					toast.error(
						"Unable to add Permission to Role. " + response.data.message
					);
					console.error(
						"Unable to add Permission to Role. " + response.data.message
					);
				}
			} catch (error: any) {
				toast.error("Unable to add Permission to Role. " + error);
				console.error("Unable to add Permission to Role. " + error);
			}
		} else {
			try {
				const response = await rolePermissionApi.removeRolePermission(
					linkToAdd
				);

				if (response.data && response.data.responseStatus === ResponseStatus.Success) {
					toast.success("Removed Permission from Role");
				} else {
					toast.error(
						"Unable to remove Permission from Role. " + response.data.message
					);
					console.error(
						"Unable to remove Permission from Role. " + response.data.message
					);
				}
			} catch (error: any) {
				toast.error("Unable to remove Permission from Role. " + error);
				console.error("Unable to remove Permission from Role. " + error);
			}
		}
	}

	function drawNode(args) {
		if (currentRole.isGlobal !== true) {
			return;
		}
        let chb = args.node.querySelector('.e-checkbox-wrapper');
        chb.classList.add('e-checkbox-disabled');

        let txt = args.node.querySelector('.e-list-text');
        txt.classList.add('e-disabled');
    }

	async function beginCopy() {
		setShowSpinner(true);
		await rolePermissionApi.copyRole(currentRole!.id!);
		setShowSpinner(false);
		setShowOkCancelDialog(false);
		window.location.reload();
	}

	return (
		<div>
			<h5 className="text-theme fw-bold mb-4">Linked Permissions</h5>

			{currentRole.isGlobal && 
				<>
					<i style={{color: '#C12843'}}>This is a default role. It cannot be modified unless you create a copy. You can use the button below to do this.</i>
					<br />
					<button
						type="button"
						className="btn btn-theme"
						id="btnAddRole"
						style={{margin: '16px 0 16px 0'}}
						onClick={() => setShowOkCancelDialog(true)}
					>
						Copy Role
					</button>
				</>
			}

			{/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore */}
			<TreeViewComponent
				ref={(treeview) => {}}
				loadOnDemand={false}
				nodeChecked={nodeChecked.bind(this)}
				fields={treeFields}
				showCheckBox={true}
				drawNode={drawNode.bind(this)}
			/>


			<OkCancelDialog
				showDialog={showOkCancelDialog}
				dialogTitle="Copy Role?"
				dialogMessage="This action will result in the following: <ul><li>Copy Role</li><li>Copy Permissions</li><li>Move Users to the new Role (Where already linked)</li><li>Hide the Default Role</li></ul><br />This action cannot be undone."
				onOkClick={() => beginCopy()}
				onCancelClick={() => {
					setShowOkCancelDialog(false);
				}}
			></OkCancelDialog>

			<DialogComponent header="Copy in Progress..." visible={showSpinner} width="100px" zIndex={999}>
				<Loading />
				Please wait, The page will reload when completed.
			</DialogComponent>
		</div>
	);
};

export default RolePermissionEdit;
