import "./RolesList.css";
import {
	ColumnDirective,
	ColumnsDirective,
	DataStateChangeEventArgs,
	Filter,
	FilterSettingsModel,
	GridComponent,
	Group,
	Inject,
	Page,
	Sort,
} from "@syncfusion/ej2-react-grids";
import * as React from "react";
import { Role } from "../../../models/Role";
import { truncate } from "fs/promises";
import { Dropdown } from "react-bootstrap";
import { DeleteOutline, EditOutlined, MoreVertOutlined, SupervisorAccount, Language } from "@mui/icons-material";
import {ReactComponent as ShieldLock} from "../../../../../assets/svg/shield-lock.svg"

export interface RolesListProps {
	onEditRole: any;
	onEditUser: any;
	onEditPermission: any;
	onDeleteRole: any;
	dataSource: Role[];
}

export default function RolesList(rolesListProps: RolesListProps) {
	const rolesGrid = React.useRef<GridComponent | null>();
	const gridPageSize = 10;
	const pageSettings = { pageSize: gridPageSize };
	const sortSettings = {
		columns: [{ field: "name", direction: "Ascending" }],
	};

	function roleNameTemplate(roleNameProps: any) {
		return (
			<>
				{roleNameProps.name} {/* {roleNameProps.isGlobal && <Language/>} */}
			</>
		)
	}

	function actionsTemplate(actionTemplateProps: any) {
		return (
			<div>
				<Dropdown>
					<Dropdown.Toggle variant="link" className="text-theme" id="dropdown-basic">
						<MoreVertOutlined/>
					</Dropdown.Toggle>
					
					<Dropdown.Menu>
						<Dropdown.Item className="text-theme fw-bold" 
						onClick={() => {
							const currentRole = actionTemplateProps;
							rolesListProps.onEditRole(currentRole);
						}}>
							<EditOutlined/> Edit Role
						</Dropdown.Item>

						<Dropdown.Item className="text-theme fw-bold" 
						onClick={() => {
							const currentRole = actionTemplateProps;
							rolesListProps.onDeleteRole(currentRole);
						}}>
							<DeleteOutline/> Delete Role
						</Dropdown.Item>

						<Dropdown.Item className="text-theme fw-bold"
						onClick={() => {
							const currentRole = actionTemplateProps;
							rolesListProps.onEditUser(currentRole);
						}}>
							<SupervisorAccount/> Users
						</Dropdown.Item>

						<Dropdown.Item className="text-theme fw-bold"
						onClick={() => {
							const currentRole = actionTemplateProps;
							rolesListProps.onEditPermission(currentRole);
						}}>
							<ShieldLock width={'21px'} height={'20px'}/> Permissions
						</Dropdown.Item>

						{/* {actionTemplateProps.isGlobal && 
							<Dropdown.Item className="text-theme fw-bold"
							onClick={() => {
								const currentRole = actionTemplateProps;
								rolesListProps.onEditPermission(currentRole);
							}}>
								<SupervisorAccount/> Allow Editing
							</Dropdown.Item>
						} */}
					</Dropdown.Menu>


				</Dropdown>
			</div>
		);
	}

	const rolesDataBound = () => {
		if (
			rolesGrid.current &&
			rolesGrid.current.dataSource instanceof Array &&
			!(rolesGrid.current.dataSource as object[]).length
		) {
			const state = { skip: 0, take: gridPageSize };
			rolesStateChange(state);
		}
	};

	const gridFilter: FilterSettingsModel = {
        type: 'Menu'
    };
	
	const rolesStateChange = (e: DataStateChangeEventArgs) => {
		if (rolesGrid.current) {
			rolesGrid.current.dataSource = rolesListProps.dataSource;
		}
	};

	// const onActionFailure = (e) => {
	//   document.getElementById("spanError").innerHTML = "Server exception: 404 Not found";
	// }

	return (
		<div className="RolesList">
			<span id="spanError" style={{ color: "#ff0000" }} />
			<GridComponent
				id={"rolesGrid"}
				ref={(grid) => {
					rolesGrid.current = grid;
				}}
				dataSource={rolesListProps.dataSource}
				allowPaging={true}
				pageSettings={pageSettings}
				allowSorting={true}
				allowSelection={false}
				allowFiltering={true}
				dataBound={rolesDataBound}
				dataStateChange={rolesStateChange}
				statelessTemplates={["directiveTemplates"]}
				filterSettings={gridFilter}
			>
				<ColumnsDirective>
					<ColumnDirective 
						headerText="Role Name" 
						template={roleNameTemplate}
						width={100} 
					/>
					<ColumnDirective
						headerText="Role Description"
						field="description"
						width={150}
					/>
					<ColumnDirective
						field="Actions"
						headerText="Actions"
						width="100"
						allowFiltering={false}
						allowSorting={false}
						template={actionsTemplate}
						textAlign="Right"
					/>
				</ColumnsDirective>
				<Inject services={[Page, Sort, Filter]} />
			</GridComponent>
		</div>
	);
}
