import { UserManager } from 'oidc-client';

var thisUrl = process.env.REACT_APP_BASE_URI.replace("https:", window.location.protocol) + process.env.REACT_APP_PORTAL_URL;

const config = {
  authority: process.env.REACT_APP_BASE_URI + process.env.REACT_APP_SARM_URL,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: thisUrl + '#/signin-callback?',
  response_type: process.env.REACT_APP_OIDC_RESPONSE_TYPE,
  scope: process.env.REACT_APP_OIDC_SCOPE,
  post_logout_redirect_uri: thisUrl + 'signout-oidc',
  store: process.env.REACT_APP_OIDC_STORE
};

export const userManager = new UserManager(config);