import ApiService from "./api-service";

const AccountApi = {
  /**
   * Log the user into the API.
   *
   * @param {object} credentials - Username and password.
   */
  login(form: FormData) {
    const url = `auth/Login`;
    return ApiService.post(url, form);
  },

};

export default AccountApi;
