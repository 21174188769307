import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { get as _get } from "lodash";
import { reset } from "../mutators";
import StorageService from "../../common/storage-service";
import { RootState } from "../index";
import AccountApi from "../../common/AccountApi";
import { AuthData } from "../../models/AuthData";

const initialState: AuthData = {
	token: null,
	user: null,
	userData: null,
	rememberMe: false,
	error: null,
	loading: false,
};

export const AuthSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		// Set state.
		SET_TOKEN: (state, action: PayloadAction<any>) => {
			state.token = action.payload;

			StorageService.set(
				process.env.REACT_APP_TOKEN_KEY,
				action.payload.token,
				state.rememberMe
			);
		},
		SET_USER: (state, action: PayloadAction<any>) => {
			state.user = action.payload;

			StorageService.set(
				process.env.REACT_APP_USER_KEY,
				JSON.stringify(action.payload),
				state.rememberMe
			);
		},
		SET_REMEMBER_ME: (state, action: PayloadAction<any>) => {
			state.rememberMe = action.payload.rememberMe;

			StorageService.set(
				process.env.REACT_APP_REMEMBER_ME_KEY,
				action.payload.rememberMe,
				true
			);
		},
		SET_USER_DATA: (state, action: PayloadAction<any>) => {
			state.userData = action.payload.userData;
		},
		SET_LOADING: (state, action: PayloadAction<any>) => {
			state.loading = action.payload.loading;
		},
		SET_ERROR: (state, action: PayloadAction<any>) => {
			// Initialise with status code error.
			let error = action.payload.error;
			let message;
			if (!error?.response) {
				message = "Network Error: Unable to reach server.";
			} else {
				// Check all expected properties for an error message.
				const message1 = _get(error, "response.data.error", null);
				const message2 = _get(error, "response.data.Message", null);
				const message3 = _get(error, "response.data.message", null);

				message = message1 || message2 || message3;
			}
			state.error = message;
		},
		// Clear state.
		CLEAR_TOKEN: (state) => {
			StorageService.delete(process.env.REACT_APP_TOKEN_KEY);
			state.token = null;
		},
		CLEAR_USER: (state) => {
			StorageService.delete(process.env.REACT_APP_USER_KEY);
			state.user = null;
		},
		CLEAR_REMEMBER_ME: (state) => {
			StorageService.delete(process.env.REACT_APP_REMEMBER_ME_KEY);
			state.rememberMe = false;
		},
		CLEAR_ERROR: (state) => {
			state.error = null;
		},
		// Reset state.
		RESET: (state) => {
			// Purge persisted data.
			const rememberMe = StorageService.get(
				process.env.REACT_APP_REMEMBER_ME_KEY
			);
			StorageService.delete(process.env.REACT_APP_TOKEN_KEY);

			if ("true" !== rememberMe) {
				StorageService.delete(process.env.REACT_APP_USER_KEY);
			}

			try {
				let newState = {
					token: null,
					user: state.user,
					userData: null,
					rememberMe: state.rememberMe,
					error: null,
					loading: false,
				};
				reset(state, newState);
			} catch (e) {
				console.error(e);
			}
		},
	},
});

/* States */
export const error = (state: RootState) => state.RootReducer.AuthReducer.error;
export const user = (state: RootState) => state.RootReducer.AuthReducer.user;
export const getRememberMe = (state: RootState) =>
	state.RootReducer.AuthReducer.rememberMe;

/* Getters */
export const hasToken = (state: RootState) => {
	return state.RootReducer.AuthReducer.token != null;
};

/* Actions */
export const login = createAsyncThunk(
	"auth/login",
	async (credentials: FormData, { dispatch }) => {
		try {
			await AccountApi.login(credentials);
		} catch (error) {
			dispatch(SET_ERROR({ error }));
		}
	}
);

export const logout = createAsyncThunk(
	"auth/logout",
	async (_: void, { dispatch }) => {
		const rememberMe = StorageService.get(
			process.env.REACT_APP_REMEMBER_ME_KEY
		);
		//AccountApi.logout();  from ramsha - add logout function

		if ("true" === rememberMe) {
			dispatch(RESET());
		} else {
			// Clear all application state.
			//dispatch(resetAppState());
		}
	}
);

// Action creators are generated for each case reducer function
export const {
	SET_TOKEN,
	SET_USER,
	SET_REMEMBER_ME,
	SET_USER_DATA,
	SET_ERROR,
	RESET,
} = AuthSlice.actions;

export default AuthSlice.reducer;
