import ApiService from "../../../common/api-service";
import { RolePermissionLink } from "../models/RolePermissionLink";


export const rolePermissionApi = {

    async getRolePermissions(roleId: string) {
        const url = `rolePermission/get-role-permissions?roleId=`;
        return await ApiService.get(url + roleId);
    },

    async addRolePermission(rolePermissionLink: RolePermissionLink) {
        const url = `rolePermission/set-role-permission`;
        return await ApiService.post(url, rolePermissionLink)
    },

    async removeRolePermission(rolePermissionLink: RolePermissionLink) {
        const url = `rolePermission/remove-role-permission`;
        return await ApiService.post(url, rolePermissionLink)
    },

    async copyRole(roleId: string) {
        const url = `role/duplicate?sourceId=`;
        return await ApiService.get(url + roleId);
    }

};