import { enableRipple } from "@syncfusion/ej2-base";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import React, { FC, useEffect } from "react";
import { userRoleApi } from "../../../api/user-role-management";
import ApiService from "../../../../../common/api-service";
import { UserRoleLink } from "../../../models/UserRoleLink";
import { Company } from "../../../models/Company";
import { toast } from "react-toastify";
import { Role } from "../../../models/Role";
import { ResponseStatus } from "../../../models/Enum";

enableRipple(true);
export interface RoleUserEditProps {
	currentRole: Role | undefined;
	companies: Company[];
}

const RoleUserEdit: FC<RoleUserEditProps> = ({ currentRole, companies }) => {
	const [treeFields, setTreeFields] = React.useState<Object>();
	const [selectedCompany, setSelectedCompany] =
		React.useState<string>("undefined");

	const fetchLinks = async (companyId: string) => {
		try {
			if (!currentRole || !currentRole.id || currentRole.id === "undefined") {
				return;
			}
			const response = await ApiService.get(
				"userRole/get-all-users-and-links?roleId=" +
					currentRole?.id +
					"&companyId=" +
					companyId
			); //ADD COMPANY IF SET!! get API to filter response.
			if (response.data && response.data.responseStatus === ResponseStatus.Success) {
				let actualResponse = response.data.data;
				if (companyId !== "undefined") {
					actualResponse = filterCompanies(actualResponse, companyId);
				}
				let newData: object = {
					dataSource: actualResponse,
					id: "id",
					text: "name",
					child: "companyList",
					isChecked: "selected",
					companyId: "companyId",
					userId: "userId",
				};
				setTreeFields(newData);
			} else {
				toast.error(
					"Unable to fetch Users against the role. " + response.data.error
				);
				console.error(
					"Unable to fetch Users against the role. " + response.data.error
				);
			}
		} catch (error: any) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchLinks(selectedCompany);
	}, [currentRole, selectedCompany]);
	
	function filterCompanies(users: any[], companyId: string): any[] {
		const filteredUsers: any[] = [];

		for (const user of users) {
			const { id, name, companyList } = user;
			const companies = companyList.filter(
				(company: any) => company.companyId === companyId
			);

			if (companies.length > 0) {
				const {
					id: companyId,
					name: companyName,
					...companyProps
				} = companies[0];

				const modifiedUser = {
					id,
					name,
					companyId,
					companyName,
					...companyProps,
				};

				filteredUsers.push(modifiedUser);
			}
		}

		return filteredUsers;
	}

	const companySelected = (e: any) => {
		const { id, value, checked } = e.target;
		fetchLinks(value);
		setSelectedCompany(value);
	};

	const nodeChecked = (args: any) => {
		let checkedNode: string | Element | any = args.data[0];

		if (checkedNode.hasChildren === true) {
			for (let i = 0; i < args.data.length; i++) {
				if (i === 0) {
					continue;
				}
				updateNode(args.data[i]);
			}
		} else {
			updateNode(checkedNode);
		}
	};

	async function updateNode(targetNode: any) {
		if (currentRole === null) {
			return;
		}
		let selectedRoleId = currentRole?.id;
		var linkToAdd: UserRoleLink;

		if (targetNode.parentID === null) {
			const link: UserRoleLink = {
				userId: targetNode.id,
				companyId: selectedCompany,
				roleId: selectedRoleId,
			};
			linkToAdd = link;
		} else {
			const link: UserRoleLink = {
				userId: targetNode.parentID,
				companyId: targetNode.id.split(":")[1],
				roleId: selectedRoleId,
			};
			linkToAdd = link;
		}

		if (targetNode.isChecked === "true") {
			try {
				const response = await userRoleApi.addUserRole(linkToAdd);
				if (response.data && response.data.responseStatus === ResponseStatus.Success) {
					toast.success("Added User to Role");
				} else {
					toast.error("Unable to add User to Role. " + response.data.message);
					console.error("Unable to add User to Role. " + response.data.message);
				}
			} catch (error: any) {
				toast.error("Unable to add User to Role. " + error);
				console.error("Unable to add User to Role. " + error);
			}
		} else {
			try {
				const response = await userRoleApi.removeUserRole(linkToAdd);

				if (response.data && response.data.responseStatus === ResponseStatus.Success) {
					toast.success("Removed User from Role");
				} else {
					toast.error(
						"Unable to remove User from Role. " + response.data.message
					);
					console.error(
						"Unable to remove User from Role. " + response.data.message
					);
				}
			} catch (error: any) {
				toast.error("Unable to remove User from Role. " + error);
				console.error("Unable to remove User from Role. " + error);
			}
		}
	}

	return (
		<div>
			<div className="mb-4">
				<label htmlFor="DefaultCompanyId" className="fw-bold">Default Company</label>
				<select className="form-control" id="DefaultCompanyId" onChange={companySelected}>
					<option value="undefined">All Companies</option>
					{companies?.map((company, index) => {
						return (
							<option key={index} value={company.id}>
								{company.name}
							</option>
						);
					})}
				</select>
			</div>

			{/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore */}
			<TreeViewComponent
				loadOnDemand={false}
				nodeChecked={nodeChecked.bind(this)}
				fields={treeFields}
				showCheckBox={true}
			/>
		</div>
	);
};

export default RoleUserEdit;
