import { useState, useEffect } from "react";
import "./ManageRoles.css";
import RolesList from "../RolesList/RolesList";
import RoleAddEdit from "../RoleAddEdit/RoleAddEdit";
import ApiService from "../../../../../common/api-service";
import { Role } from "../../../models/Role";
import { Company } from "../../../models/Company";
import { toast } from "react-toastify";
import OkCancelDialog from "../../Common/OkCancelDialog/OkCancelDialog";
import { userManager } from "../../../../../common/user-service";
import {AuditModal} from '@ibcos/auditviewer';
import { ResponseStatus } from "../../../models/Enum";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import RoleUserEdit from "../RoleUserEdit/RoleUserEdit";
import RolePermissionEdit from "../RolePermissionEdit/RolePermissionEdit";
import { User } from "oidc-client";

export default function ManageRoles() {
	const [roles, setRoles] = useState<Role[] | []>([]);
	const [showOkCancelDialog, setShowOkCancelDialog] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [isUser, setIsUser] = useState(false);
	const [isPermission, setIsPermission] = useState(false);
	const [isAddRole, setIsAddRole] = useState(false);
	const [newRole, setNewRole] = useState<Role>(new Role());
	const [editRole, setEditRole] = useState<Role>(new Role());
	const [roleIdToDelete, setRoleIdToDelete] = useState<string | undefined>("");
	const [companies, setCompanies] = useState<Company[]>([]);
	const [accessTokenString, setAccessTokenString] = useState<string>("");
	
	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const response = await ApiService.get("role/list");
				if (response.data && response.data.responseStatus === ResponseStatus.Success) {
					const rolesList = response.data.data;
					setRoles(rolesList);
				} else {
					toast.error("Unable to fetch Roles. " + response.data.message);
					console.error("Unable to fetch Roles. " + response.data.message);
				}
			} catch (error: any) {
				console.error(error);
				toast.error("Unable to fetch data. " + error);
			}
		};

		const fetchCompanies = async () => {
			try {
				//const response = await ApiService.get("company/list");
				const response = await ApiService.get("company/get-by-current-dealership");
				if (response.data && response.data.responseStatus === ResponseStatus.Success) {
					const companyList = response.data.data;
					setCompanies(companyList);
				} else {
					toast.error(
						"Unable to fetch Companies. " + response.data.message
					);
					console.error(
						"Unable to fetch Companies. " + response.data.message
					);
				}
			} catch (error: any) {
				console.error(error);
				toast.error("Unable to get companies. " + error);
			}
		};

		fetchRoles();
		fetchCompanies();
	}, []);

	const onbtnAddRoleClick = () => {
		const newRole = new Role();
		setEditRole(newRole);
		setIsAddRole(true);
		setNewRole(newRole);
	};

	const onEditRole = (currentRole: Role) => {
		setNewRole(new Role());
		setIsEdit(true);
		setEditRole(currentRole);
	};
	
	const onEditUser = (currentRole: Role) => {
		setNewRole(new Role());
		setIsUser(true);
		setEditRole(currentRole);
	};
	
	const onEditPermission = (currentRole: Role) => {
		setNewRole(new Role());
		setIsPermission(true);
		setEditRole(currentRole);
	};

	const onDeleteRoleClick = (currentRole: Role) => {
		setRoleIdToDelete(currentRole.id);
		setShowOkCancelDialog(true);
	};

	const onDeleteRole = async () => {
		try {
			const data = {};
			const response = await ApiService.httpDelete(
				"role/delete?id=" + roleIdToDelete,
				data
				);
				if (response.data && response.data.responseStatus === ResponseStatus.Success) {
				setRoles((roles) => roles.filter((r) => r.id !== roleIdToDelete));
				toast.success("Role Deleted Successfully");
				onbtnAddRoleClick();
				setShowOkCancelDialog(false);
			} else {
				toast.error("Unable to delete the role. " + response.data.Message);
				console.error(
					"Unable to delete the role. " + response.data
					);
				}
			} catch (error: any) {
			toast.error("Unable to delete the role. " + error.response.data.Message);
			console.error(error);
		}
	};

	const inputChangeHandler = (name: string, value: string | boolean) => {
		if (isEdit) {
			setEditRole((prevRole) => ({ ...prevRole, [name]: value }));
		} else {
			setNewRole((prevRole) => ({ ...prevRole, [name]: value }));
		}
	};

	const isEmptyOrSpaces = (str: string) => {
		return str === null || str.match(/^ *$/) !== null;
	};

	const cancelAddEdit = () => {
		setIsEdit(false);
		setIsUser(false);
		setIsPermission(false);
		setIsAddRole(false);
	}

	const onAdd = async () => {
		if (isEmptyOrSpaces(newRole.name)) {
			const errorMessage = "Role name can not be empty...";
			toast.error(errorMessage);
			console.error(errorMessage);
			return;
		}
		try {
			const data = newRole;
			const response = await ApiService.post("role/add", data);
			if (response.data && response.data.responseStatus === ResponseStatus.Success) {
				setRoles([...roles, response.data.data]);
				toast.success("Role Added Successfully");
				setIsAddRole(false);
			} else {
				toast.error("Unable to add this role. " + response.data.message);
				console.error("Unable to add this role. " + response.data.message);
			}
		} catch (error: any) {
			toast.error("Unable to add this role. " + error);
			console.error(error);
		}
	};

	const onUpdate = async () => {
		if (isEmptyOrSpaces(editRole.name)) {
			const errorMessage = "Role name can not be empty...";
			toast.error(errorMessage);
			console.error(errorMessage);
			return;
		}
		try {
			const data = editRole;
			const response = await ApiService.post("role/update", data);
			if (response.data && response.data.responseStatus === ResponseStatus.Success) {
				setRoles((prevRoles) =>
					prevRoles.map((role) => (role.id === editRole.id ? editRole : role))
				);
				toast.success("Role Updated Successfully");
				onbtnAddRoleClick();
			} else {
				toast.error("Unable to update the role. " + response.data.message);
				console.error(
					"Unable to update the role. " + response.data.message
				);
			}
		} catch (error: any) {
			toast.error("Unable to update the role. " + error);
			console.error(error);
		}
	};

	async function getAccessToken(): Promise<string> {
		var user = await userManager.getUser();
		if (user!.expired) {
			await userManager.signinSilent();
			user = await userManager.getUser();
		}
		const access_token_copy: string = user!.access_token;			
		setAccessTokenString(access_token_copy);
		return access_token_copy;
	};

	useEffect(() => {
		getAccessToken()
	}, []);

	function getToken() {
		return accessTokenString;
	}

	var buttonsUserLinks = [
		{
			click: () => {
				setIsUser(false);
			},
			buttonModel: {
				content: 'Close',
				cssClass: 'btn-outline-theme btn px-4', 
			},
		},
    ];

	var buttonsPermissionLinks = [
		{
			click: () => {
				setIsPermission(false);
			},
			buttonModel: {
				content: 'Close',
				cssClass: 'btn-outline-theme btn px-4', 
			},
		},
    ];

	var buttonsEditRole = [
		{
			click: () => {
				cancelAddEdit();
			},
			buttonModel: {
				content: 'Cancel',
				cssClass: 'btn-outline-theme btn px-4', 
			},
		},
		{
			click: async () => {
				await onUpdate();
				cancelAddEdit();
			},
			buttonModel: {
				content: 'Save',
				cssClass: 'btn-theme btn px-4', //primary
			},
		},
    ];

	var buttonsAddRole = [
		{
			click: () => {
				cancelAddEdit();
			},
			buttonModel: {
				content: 'Cancel',
				cssClass: 'btn-outline-theme btn px-4', 
			},
		},
		{
			click: () => {
				onAdd();
				cancelAddEdit();
			},
			buttonModel: {
				content: 'Save',
				cssClass: 'btn-theme btn px-4', //primary
			},
		},
    ];


	return (
		<>
			<AuditModal viewName="SARM Roles" getToken={getToken} auditApiUrl={process.env.REACT_APP_BASE_URI! + process.env.REACT_APP_AUDIT_URL} />
			<div className="row">
				<div className="col-6 mb-4">
					<h4 className="text-theme fw-bold mb-0">User Roles</h4>
				</div>
				<div className="col-6 text-end mb-4">		
					<button
						type="button"
						className="btn btn-theme"
						id="btnAddRole"
						onClick={onbtnAddRoleClick}
					>
						Add New Role
					</button>
				</div>
			</div>
			<div className="ManageRoles">
				<div className="row">
					<div className="col-12">
						<RolesList
							onEditRole={onEditRole}
							onDeleteRole={onDeleteRoleClick}
							onEditUser={onEditUser}
							onEditPermission={onEditPermission}
							dataSource={roles}
						/>
					</div>
				</div>				
			</div>
			<DialogComponent 
			 visible={isAddRole} 
			 target={'#root'}
			 position={{X: 'center', Y: 'center'}}
			 width="500"
			 height="400px"
			 header="Add New Role"
			 buttons={buttonsAddRole}>
				<RoleAddEdit
					currentRole={newRole}
					changeHandler={inputChangeHandler}
				/>
			</DialogComponent>
			<DialogComponent 
			 visible={isEdit} 
			 target={'#root'}
			 position={{X: 'center', Y: 'center'}}
			 width="500px"
			 height="400px"
			 header="Edit Role"
			 buttons={buttonsEditRole}
			 zIndex={920}>
				<RoleAddEdit
					currentRole={editRole}
					changeHandler={inputChangeHandler}
				/>
			</DialogComponent>

			<DialogComponent 
			 visible={isUser} 
			 target={'#root'}
			 position={{X: 'center', Y: 'center'}}
			 width="500px"
			 height="400px"
			 header="Edit User/Role Links"
			 buttons={buttonsUserLinks}>
				<RoleUserEdit currentRole={editRole} companies={companies} />
			</DialogComponent>

			<DialogComponent 
			 visible={isPermission} 
			 target={'#root'}
			 position={{X: 'center', Y: 'center'}}
			 width="500px"
			 height="400px"
			 header="Edit Permission/Role Links"
			 buttons={buttonsPermissionLinks}
			 zIndex={920}>
				<RolePermissionEdit currentRole={editRole} />
			</DialogComponent>


			<OkCancelDialog
				showDialog={showOkCancelDialog}
				dialogTitle="Delete Role?"
				dialogMessage="Are you sure, you want to delete this role?"
				onOkClick={onDeleteRole}
				onCancelClick={() => {
					setShowOkCancelDialog(false);
					setRoleIdToDelete("");
				}}
			></OkCancelDialog>
		</>
	);
}
