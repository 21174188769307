import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../common/api-service";
import { Role } from "../../modules/portal/models/Role";
import { RootState } from "..";

export interface Roles {
	roles: {
		list: Role[];
	};
}

const initialState: Roles = {
	roles: {
		list: [],
	},
};

export const RoleSlice = createSlice({
	name: "roles",
	initialState,
	reducers: {
		GET_ROLES: (state, action) => {
			state.roles.list = action.payload.rolesList;
		},
	},
});

export const getRoles = (state: RootState) => {
	return state.RootReducer.RoleReducer.roles.list;
};

export const getRolesList = createAsyncThunk(
	"role/list",
	async (args: string, { dispatch }) => {
		try {
			const response = await ApiService.get("role/list");
			const rolesList = response.data.data;
			dispatch(GET_ROLES({ rolesList }));
		} catch (error: any) {
			console.error(error);
		}
	}
);

export const { GET_ROLES } = RoleSlice.actions;

export default RoleSlice.reducer;
