import React, { useState, useEffect } from 'react';
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import ApiService from "../../../../../common/api-service";
import { Company } from "../../../models/Company";
import { toast } from "react-toastify";
import { ResponseStatus } from "../../../models/Enum";
import { Role } from "../../../models/Role";
import { UserRoleLink } from "../../../models/UserRoleLink";
import { UserDetail } from "../../../models/UserDetail";
import { userRoleApi } from "../../../api/user-role-management";
import { CancelOutlined } from '@mui/icons-material';

interface UserRoleDialogProps {
    open: boolean;
    user: UserDetail;
    companies: Company[];
    onClose: () => void;
}

const UserRoleDialog: React.FC<UserRoleDialogProps> = ({
    open,
    user,
    companies,
    onClose
}) => {
    const [treeFields, setTreeFields] = React.useState<Object>();
    const [selectedCompany, setSelectedCompany] = React.useState<string>("undefined");

    useEffect(() => {
        if (open) {
            fetchLinks(selectedCompany);
        }
    }, [open]);

    const fetchLinks = async (companyId: string) => {
        try {
            const response = await ApiService.get(
                "userRole/get-all-roles-and-links?UserId=" +
                user?.id +
                "&companyId=" +
                companyId
            ); //ADD COMPANY IF SET!! get API to filter response.
            if (response.data && response.data.responseStatus === ResponseStatus.Success) {
                let actualResponse = response.data.data;
                if (companyId !== "undefined") {
                    actualResponse = filterCompanies(actualResponse, companyId);
                }
                let newData: object = {
                    dataSource: actualResponse,
                    id: "id",
                    text: "name",
                    child: "companyList",
                    isChecked: "selected",
                    companyId: "companyId",
                    roleId: "roleId",
                };
                setTreeFields(newData);
            } else {
                toast.error(
                    "Unable to fetch Users against the role. " + response.data.error
                );
                console.error(
                    "Unable to fetch Users against the role. " + response.data.error
                );
            }
        } catch (error: any) {
            console.error(error);
        }
    };

    function filterCompanies(roles: any[], companyId: string): any[] {
        const filteredRoles: any[] = [];

        for (const role of roles) {
            const { id, name, companyList } = role;
            const companies = companyList.filter(
                (company: any) => company.companyId === companyId
            );

            if (companies.length > 0) {
                const {
                    id: companyId,
                    name: companyName,
                    ...companyProps
                } = companies[0];

                const modifiedRole = {
                    id,
                    name,
                    companyId,
                    companyName,
                    ...companyProps,
                };

                filteredRoles.push(modifiedRole);
            }
        }

        return filteredRoles;
    }

    const companySelected = (e: any) => {
        const { id, value, checked } = e.target;
        fetchLinks(value);
        setSelectedCompany(value);
    };

    const nodeChecked = async (args: any) => {
        let checkedNode: string | Element | any = args.data[0];

        if (checkedNode.hasChildren === true) {
            for (let i = 0; i < args.data.length; i++) {
                if (i === 0) {
                    continue;
                }
                await updateNode(args.data[i]);
            }
        } else {
            await updateNode(checkedNode);
        }
    };


    async function updateNode(targetNode: any) {

        let selectedUserId = user.id;
        var linkToAdd: UserRoleLink;

        if (targetNode.parentID === null) {
            const link: UserRoleLink = {
                userId: selectedUserId,
                companyId: selectedCompany,
                roleId: targetNode.id,
            };
            linkToAdd = link;
        } else {
            const link: UserRoleLink = {
                userId: selectedUserId,
                companyId: targetNode.id.split(":")[1],
                roleId: targetNode.parentID,
            };
            linkToAdd = link;
        }

        if (targetNode.isChecked === "true") {
            try {
                const response = await userRoleApi.addUserRole(linkToAdd);
                if (response.data && response.data.responseStatus === ResponseStatus.Success) {
                    toast.success("Added User to Role");
                } else {
                    toast.error("Unable to add User to Role. " + response.data.message);
                    console.error("Unable to add User to Role. " + response.data.message);
                }
            } catch (error: any) {
                toast.error("Unable to add User to Role. " + error);
                console.error("Unable to add User to Role. " + error);
            }
        } else {
            try {
                const response = await userRoleApi.removeUserRole(linkToAdd);

                if (response.data && response.data.responseStatus === ResponseStatus.Success) {
                    toast.success("Removed User from Role");
                } else {
                    toast.error(
                        "Unable to remove User from Role. " + response.data.message
                    );
                    console.error(
                        "Unable to remove User from Role. " + response.data.message
                    );
                }
            } catch (error: any) {
                toast.error("Unable to remove User from Role. " + error);
                console.error("Unable to remove User from Role. " + error);
            }
        }
    }

    var buttons = [
		{
			click: () => {
				onClose();
			},
			buttonModel: {
				content: 'Close',
				cssClass: 'btn-theme btn px-4', //primary
			},
		},
	];


    return (
        <DialogComponent 
        width="500px"
        visible={open} 
        title="Manage User Roles" 
        buttons={buttons}>
                    <div>                   
                        <div className="inputDiv mb-4">
                            <label htmlFor="DefaultCompanyId" className='fw-bold'>Default Company</label>
                            <select id="DefaultCompanyId" className="form-control" onChange={companySelected}>
                                <option value="undefined">All Companies</option>
                                {companies?.map((company, index) => {
                                    return (
                                        <option key={index} value={company.id}>
                                            {company.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore */}
                        <TreeViewComponent
                            loadOnDemand={false}
                            nodeChecked={nodeChecked.bind(this)}
                            fields={treeFields}
                            showCheckBox={true}
                        />
                    </div>
        </DialogComponent>
    );
};

export default UserRoleDialog;
