import { createSpinner, showSpinner } from "@syncfusion/ej2-react-popups";
import React from "react";

const Loading:React.FC = () => {


    return (
        <div class="d-flex justify-content-center">
            <br /><br />
            <div class="spinner-border spinner-large" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <br /><br />
        </div>
    );
}

export default Loading;