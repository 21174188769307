import ApiService from "../../../common/api-service";
import { UserRoleLink } from "../models/UserRoleLink";


export const userRoleApi = {

    async getUserRoles() {
        const url = `userRole/get-all-users-and-links`;
        return await ApiService.get(url);
    },

    async addUserRole(UserRoleLink: UserRoleLink) {
        //const url = `userRole/set-role-user-company`;
        const url = `userRole/add`;
        return await ApiService.post(url, UserRoleLink)
    },

    async removeUserRole(UserRoleLink: UserRoleLink) {
        //const url = `userRole/remove-role-user-company`;
        const url = `userRole/delete`;
        return await ApiService.httpDelete(url, UserRoleLink)
    }

};