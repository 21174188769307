export class Role {
	id: string | undefined;
	name: string = "";
	description: string = "";
	isGlobal: boolean = false;
	active: boolean = true;
	deleted: boolean = false;

	constructor(initializer?: Role) {
		if (!initializer) {
			this.name = "";
			this.description = "";
			this.active = true;
			this.isGlobal = false;
			return;
		}

		if (initializer.id) this.id = initializer.id;
		if (initializer.name) this.name = initializer.name;
		if (initializer.description) this.description = initializer.description;
		if (initializer.active) this.active = initializer.active;
		if (initializer.isGlobal) this.isGlobal = initializer.isGlobal;
		if (initializer.deleted) this.deleted = initializer.deleted;
	}
}
