import React, { useState, useEffect, useRef } from "react";
import { useAppDispatch } from "../../../../hooks/hooks";
import { UserDetail } from "../../models/UserDetail";
import {
	deleteUser as UserDelete,
	updateUserPassword,
	resendInvite as sendAnotherInvite,
	addUser,
	editUser
} from "../../../../store/modules/UserSlice";
import UserAddEdit from "./UserAddEdit";
import { Company } from "../../models/Company";
import ApiService from "../../../../common/api-service";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { toast } from "react-toastify";
import OkCancelDialog from "../Common/OkCancelDialog/OkCancelDialog";
import { AuditModal } from '@ibcos/auditviewer';
import { userManager } from "../../../../common/user-service";
import NewPassword from "../NewPassword/NewPassword";
import UserRole from "./UserRole/UserRole";
import { ResponseStatus } from "../../models/Enum";
import { Dropdown } from 'react-bootstrap';
import './user.css'
import { DeleteOutline, EditOutlined, GppMaybeOutlined, LockResetRounded, MoreVertOutlined, RepartitionOutlined } from "@mui/icons-material";
import {Inject,	ColumnDirective, ColumnsDirective, DataStateChangeEventArgs, GridComponent, Page, Sort, Filter, FilterSettingsModel} from "@syncfusion/ej2-react-grids";

const ManageUser: React.FC = () => {

	const dispatcher = useAppDispatch();
	const [showUserModal, setShowUserModal] = useState(false);
	const [showUserModalEdit, setShowUserModalEdit] = useState(false);
	const [showUserDelete, setShowUserDelete] = useState(false);
	const [showUserPassword, setShowUserPassword] = useState(false);
	const [showUserRoleModal, setShowUserRoleModal] = useState(false);
	const [showUserResendInvite, setShowUserResendInvite] = useState(false);
	const [selectedUserInvitations, setSelectedUserInvitations] = useState<string>("0");
	const [selectedUserID, setSelectedUserID] = useState<string>("");
	const [editUserInfo, setEditUserInfo] = useState<UserDetail>(new UserDetail());
	const [editUserRoleInfo, seteditUserRoleInfo] = useState<UserDetail>();
	const [accessTokenString, setAccessTokenString] = useState<string>("");
	const [usersList, setUsersList] = useState<UserDetail[] | []>([]);
	const [companies, setCompanies] = useState<Company[]>();
	const [input, setInput] = useState({
		userId: "",
		password: "",
		isValid: false,
	});
	const userListGrid = React.useRef<GridComponent | null>();

	useEffect(() => {
		getUsersList();
		getCompanies();
	}, []);

	const getUsersList = async () => {
		try {
			const response = await ApiService.get("user/list");
			const users = response.data.data;
			setUsersList(users);
		} catch (error: any) {
			toast.error("Unable to get users " + error);
		}
	};

	const getCompanies = async () => {
		try {
			const response = await ApiService.get("depot/list-by-company");
			const companyList = response.data.data;
			setCompanies(companyList);
		} catch (error: any) {
			console.error(error);
			toast.error("Unable to get companies. " + error);
		}
	};



	function hideUserModals() {
		setShowUserModalEdit(false);
		setShowUserModal(false);
	}

	useEffect(() => {
	}, [editUserInfo]);

	const inputChangeHandler = (name: string, value: string | boolean) => {
		console.log(`${name}: ${value}`)
		setEditUserInfo((user) => ({ ...user, [name.toLocaleLowerCase()]: value }));
	};

	const resetUpdateUser = (
		responseType: string,
		responseMessage: string,
		type: string,
		data: any
	) => {
		if (type === "add") {
			if (responseType === "success") {
				toast.success("User Added Successfully");

				setUsersList([...usersList, data]);
			} else {
				toast.error("Unable to add user: " + data.Message);
				return;
			}
		} else {
			if (responseType === "success") {
				toast.success("User Updated Successfully");

				setUsersList((prevUsers) =>
					prevUsers.map((user) => (user.id === data.id ? data : user))
				);
			} else {
				toast.error("Unable to update user: " + responseMessage);
				return;
			}
		}
		setShowUserModal(false);
		setShowUserModalEdit(false);
		setEditUserInfo({} as UserDetail);
	};

	function resetAddEdit(isEdit: boolean, props: any) {
		if (props.id === '' && isEdit) {
			return;
		}
		setEditUserInfo((e) => ({...e, 'defaultcompanyid': props.id === '' ? '' : props.defaultCompanyId}))
		setEditUserInfo((e) => ({...e, 'defaultCompanyId': props.id === '' ? '' : props.defaultCompanyId}))
		setShowUserModalEdit(isEdit);
		setEditUserInfo(isEdit ? props : new UserDetail());
		setShowUserModal(!isEdit);
	}

	function setUserRoleUpdate(props: any) {
		setShowUserRoleModal(true);
		seteditUserRoleInfo(props);
	}
	
	function actionsTemplate(props: any) {
		return (
			<div>
				<Dropdown>
					<Dropdown.Toggle variant="link" className="text-theme" id="dropdown-basic">
						<MoreVertOutlined/>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item className="text-theme fw-bold" onClick={() => { resetAddEdit(true, props);}}>
							<EditOutlined/> Edit User
						</Dropdown.Item>
						<Dropdown.Item className="text-theme fw-bold" onClick={() => { confirmPassword(props.id); }}>
							<LockResetRounded/> Change Password
						</Dropdown.Item>
						<Dropdown.Item className="text-theme fw-bold" onClick={() => {deleteUserConfirm(props.id);}}>
							<DeleteOutline/> Delete User
						</Dropdown.Item>
						<Dropdown.Item className="text-theme fw-bold" onClick={() => { setUserRoleUpdate(props);}}>
							<GppMaybeOutlined/> Update Role
						</Dropdown.Item>
						{props.enabled === true ? null : (
							<Dropdown.Item className="text-theme fw-bold" onClick={() => {
									resendUserConfirm(props.id);
									setSelectedUserInvitations(props.invitationsSent);
								}}>
								<RepartitionOutlined/> Resend Invitation
							</Dropdown.Item>
						)}
					</Dropdown.Menu>
				</Dropdown>
			</div>
		);
	}

	function resendUserConfirm(userId: string) {
		setSelectedUserID(userId);
		setShowUserResendInvite(true);
	}

	function confirmPassword(userId: string) {
		setSelectedUserID(userId);
		setInput({ ...input, userId: userId });
		setShowUserPassword(true);
	}

	const handlePasswordValidation = (password: string, isValid: boolean) => {
		setInput({ ...input, password: password, isValid: isValid });
	}

	function updatePassword() {
		try {
			dispatcher(updateUserPassword(input)).then((response: any) => {

				if (response.payload !== undefined) {
					if (response.payload.responseStatus === ResponseStatus.Success) {
						if (response.payload.data === 1) {
							toast.success("User Password Updated Successfully");
							setShowUserPassword(false);
						} else {
							alert('Error updating password. Please try again');
						}
					}
				} else {
					toast.error("Unable to update password");
				}
			});
		} catch (error: any) {
			toast.error("Unable to update password:  " + error);
		}
	}

	function deleteUserConfirm(userId: string) {
		setSelectedUserID(userId);
		setShowUserDelete(true);
	}

	function deleteUser() {
		try {
			dispatcher(UserDelete(selectedUserID)).then((response: any) => {
				setShowUserDelete(false);
				if (response.payload !== undefined) {
					if (response.payload.responseStatus === ResponseStatus.Success) {
						toast.success("User Deleted Successfully");

						setUsersList(usersList.filter((r) => r.id !== selectedUserID));
					}
				} else {
					toast.error("Unable to delete user");
				}
			});
		} catch (error: any) {
			toast.error("Unable to delete user " + error);
		}
	}

	function resendInvitation() {
		try {
			dispatcher(sendAnotherInvite(selectedUserID)).then((response: any) => {
				setShowUserResendInvite(false);
				if (response.payload === true) {
						toast.success("User Invitation Sent Successfully");
						getUsersList();
				} else {
					toast.error("Unable to send invitation");
				}
			});
		} catch (error: any) {
			toast.error("Unable to send invitation.");
			console.error("Error sending invitation:", error);
		}
	}

	const gridFilter: FilterSettingsModel = {
        type: 'Menu'
    };
	const gridPageSize = 10;
	const pageSettings = { pageSize: gridPageSize };
	const renderComplete = () => {
		if (
			userListGrid.current &&
			userListGrid.current.dataSource instanceof Array &&
			userListGrid.current.dataSource.length === 0
		) {
			const state = { skip: 0, take: gridPageSize };
			dataStateChange(state);
		}
	};
	const dataStateChange = (state: DataStateChangeEventArgs) => {
		if (userListGrid.current) {
			userListGrid.current.dataSource = usersList;
		}
	};

	useEffect(() => {
		const getAccessToken = async (): Promise<string> => {
			var user = await userManager.getUser();
			if (user.expired) {
				await userManager.signinSilent();
				user = await userManager.getUser();
			}
			const access_token_copy: string = user?.access_token;
			setAccessTokenString(access_token_copy);
		};

		getAccessToken();
	}, [getToken]);

	function getToken() {
		return accessTokenString;
	}

	function UpdateUser(addNew: boolean) {
		if (addNew) {
			dispatcher(addUser(editUserInfo)).then((response: any) => {
				if (response.payload !== undefined) {
					if (response.payload.responseStatus === ResponseStatus.Success	) {
						resetUpdateUser("success", "", "add", response.payload.data);
					} else {
						resetUpdateUser(
							"error",
							response.payload.message,
							"add",
							response.payload.data
							);
						}
				} else {
					resetUpdateUser("error", "", "add", response.payload);
				}
			});
		} else {
			console.log(editUserInfo);
			delete editUserInfo.tempPassExpiry;
			console.log(editUserInfo);
			dispatcher(editUser(editUserInfo)).then((response: any) => {
				if (response.payload !== undefined) {
					if (response.payload.responseStatus === ResponseStatus.Success) {
						resetUpdateUser("success", "", "edit", response.payload.data);
						hideUserModals();
					} else {
						resetUpdateUser(
							"error",
							response.payload.message,
							"edit",
							response.payload.data
							);
						}
					} else {
						resetUpdateUser("error", "", "edit", "");
				}
			});
		}
	}

	var buttonsPasswordDialog = [
		{
			click: () => {
				setShowUserPassword(false);
			},
			buttonModel: {
				content: 'Cancel',
				cssClass: 'btn-outline-theme btn px-4', //primary
			},
		},
        {
            click: () => {
				updatePassword();
            },
            buttonModel: {
                content: 'Save',
				disabled: !input.isValid,
				cssClass: 'btn-theme btn px-4', //primary
            },
        },
    ];

	function canAdd(): boolean {
		if (editUserInfo !== undefined) {
			const areSet: boolean =
			editUserInfo.username !== "" &&
			editUserInfo.email !== "" &&
			editUserInfo.defaultcompanyid !== "" &&
			editUserInfo.password !== ""
			return areSet;
		}
		return false;
	}

	var buttonsAddDialog = [
		{
			click: () => {
				hideUserModals();
			},
			buttonModel: {
				content: 'Cancel',
				cssClass: 'btn-outline-theme btn px-4',
			},
		},
        {
            click: () => {
				UpdateUser(true);
            },
            buttonModel: {
                content: 'Save',
				disabled: canAdd(),
				cssClass: 'btn-theme btn px-4', //primary
            },
        },
    ];

	var buttonsEditDialog = [
		{
			click: () => {
				hideUserModals();
			},
			buttonModel: {
				content: 'Cancel',
				cssClass: 'btn-outline-theme btn px-4',
			},
		},
        {
            click: () => {
				UpdateUser(false);
            },
            buttonModel: {
                content: 'Save',
				cssClass: 'btn-theme btn px-4', //primary
            },
        },
    ];

	var sentInvites = "Are you sure you want to resend this invitation? Invitations Sent: " + selectedUserInvitations;
	
	return (
		<div>
			<AuditModal viewName="SARM Users" getToken={getToken} auditApiUrl={process.env.REACT_APP_BASE_URI! + process.env.REACT_APP_AUDIT_URL} />
			<div className="row">
				<div className="col-6 mb-4">
					<h4 className="text-theme fw-bold mb-0">Users</h4>
				</div>
				<div className="col-6 text-end mb-4">		
					<button
						type="button"
						className="btn btn-theme"
						onClick={() => {
							resetAddEdit(false, "");
						}}
					>
						Add New User
					</button>
				</div>
				<div id="userListDiv" className="col-lg-12">
					<GridComponent
						id={"userListGrid"}
						ref={(grid) => {
							userListGrid.current = grid;
						}}
						dataSource={usersList}
						dataBound={renderComplete}
						dataStateChange={dataStateChange}
						pageSettings={pageSettings}
						allowPaging={true}
						allowSorting={true}
						allowFiltering={true}
						statelessTemplates={["directiveTemplates"]}
						filterSettings={gridFilter}
					>
						<ColumnsDirective>
							<ColumnDirective
								field="Username"
								headerText="User Name"
								width="200"
								clipMode="EllipsisWithTooltip"
							/>
							<ColumnDirective field="Email" headerText="Email" width="200" />
							<ColumnDirective
								field="Enabled"
								headerText="Active User"
								width="200"
								clipMode="EllipsisWithTooltip"
							/>
							<ColumnDirective
								field="Actions"
								headerText="Actions"
								width="100"
								allowFiltering={false}
								allowSorting={false}
								template={actionsTemplate}
								textAlign="Right"
							/>
						</ColumnsDirective>
						<Inject services={[Sort, Page, Filter]}></Inject>
					</GridComponent>
				</div>
			</div>
			<DialogComponent
				width="500px" 
				visible={showUserModal === true} 
				header="Add New User" 
				target={'#root'}
				isModal={true} 
				resizeHandles={['All']}
				buttons={buttonsAddDialog}>
				<UserAddEdit
					type={"add"}
					currentUser={editUserInfo}
					changeHandler={inputChangeHandler}
					onClose={resetUpdateUser}
					companies={companies!}
					closeModals={hideUserModals}
				/>
			</DialogComponent>
				
			<DialogComponent  
				width="500px" 
				visible={showUserModalEdit} 
				header="Edit User" 
				target={'#root'}
				isModal={true} 
				resizeHandles={['All']}
				buttons={buttonsEditDialog}>
				<UserAddEdit
					type={"edit"}
					currentUser={editUserInfo}
					changeHandler={inputChangeHandler}
					onClose={resetUpdateUser}
					companies={companies!}
					closeModals={hideUserModals}
				/>
			</DialogComponent>

			<OkCancelDialog
				showDialog={showUserDelete}
				dialogTitle="Delete User?"
				dialogMessage="Are you sure, you want to delete this user?"
				onOkClick={deleteUser}
				onCancelClick={() => setShowUserDelete(false)}
			/>
			
			<OkCancelDialog
				showDialog={showUserResendInvite}
				dialogTitle="Resend Invitation?"
				dialogMessage={sentInvites}
				onOkClick={resendInvitation}
				onCancelClick={() => setShowUserResendInvite(false)}
			/>

			<DialogComponent 
				width="500px" 
				visible={showUserPassword} 
				header="Update Password" 
				target={'#root'}
				isModal={true} 
				resizeHandles={['All']}
				buttons={buttonsPasswordDialog} 
			>	
				<NewPassword onValidationChange={handlePasswordValidation} />
			</DialogComponent>

			<UserRole 
				open={showUserRoleModal} 
				user={editUserRoleInfo} 
				companies={companies!}
				onClose={()=>setShowUserRoleModal(false)}
				/>

		</div>
	);
}

export default ManageUser;
