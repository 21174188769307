import ReactDOM from "react-dom/client";
import router from "./router";
import { store } from "./store";
import { Provider } from "react-redux";
import ApiService from "./common/api-service";
import { RouterProvider } from "react-router-dom";

import "./styles/index.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import './styles/SyncfusionBootstrap5.css';

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

ApiService.init();

root.render(
	<Provider store={store}>
		<RouterProvider router={router} />
	</Provider>
);