import ApiService from "../../../common/api-service";
import { UserDetail, UserPassword, UserValidate, UserActivate } from "../models/UserDetail";

export const userAPI = {
	/**
	 * Get all defined users.
	 */
	async getUsers() {
		const url = `User/list`;
		return await ApiService.get(url);
	},

	async addUser(user: UserDetail) {
		const url = `/User/add`;
		return await ApiService.post(url, user);
	},

	async editUser(user: UserDetail) {
		const url = `/User/update`;
		return await ApiService.put(url, user);
	},

	async deleteUser(id: string) {
		const url = "/User/delete?id=" + id;
		return await ApiService.httpDelete(url);
	},

	async updatePassword(userPasswordUpdate: UserPassword) {
		const url = `/User/admin-update-password`;
		return await ApiService.post(url, userPasswordUpdate);
	},

	async validateEmail(user: UserValidate) {
		const url = `/User/account-verify`;
		return await ApiService.postNoHeaders(url, user);
	},

	async activateAccount(user: UserActivate) {
		const url = `/User/account-activate`;
		return await ApiService.postNoHeaders(url, user);
	},

	async resendInvitation(id: string) {
		const url = `/User/resend-invitation`;
		return await ApiService.get(url + '?userId=' + id);
	}
};
