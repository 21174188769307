export class UserDetail {
    id: string | '';
    username: string | '';
    email: string | '';
    enabled: boolean | true;
    locked: boolean | false;
    password: string | '';
    defaultcompanyid: string | '';
    defaultdepotid: string | '';
    invitationssent: number;

    constructor(initializer?: UserDetail) {
        this.id = "";
        this.username = "";
        this.email = "";
        this.enabled = false;
        this.locked = false;
        this.password = "";
        this.defaultcompanyid = "";
        this.defaultdepotid = "";
        this.invitationssent = 0;

        if(!initializer) return;
        if (initializer.id) this.id = initializer.id;
        if (initializer.username) this.username = initializer.username;
        if (initializer.email) this.email = initializer.email;
        if (initializer.enabled) this.enabled = initializer.enabled;
        if (initializer.locked) this.locked = initializer.locked;
        if (initializer.password) this.password = initializer.password;
        if (initializer.defaultcompanyid) this.defaultcompanyid = initializer.defaultcompanyid;
        if (initializer.defaultdepotid) this.defaultdepotid = initializer.defaultdepotid;
        if (initializer.invitationssent) this.invitationssent = initializer.invitationssent;
    }
}


export interface UserPassword {
    userId: string | '';
    password: string | '';
}

export interface UserValidate {
    email: string | '';
    username: string | '';
    token: string | '';
}

export interface UserActivate {
    email: string | '';
    username: string | '';
    token: string | '';
    password: string | '';
}