import * as React from 'react';
import "./upload.css";
import { useEffect, useRef, useState } from 'react';
import { createElement } from '@syncfusion/ej2-base';
import { ImageEditorComponent } from '@syncfusion/ej2-react-image-editor';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import usericon from "../../../../../assets/svg/svg-user.svg";
import { UserProfile } from '../../../../../models/User';

interface profilePictureData {
    onImageUpdate: (imageBase64: string) => void;
    onLoad: string;
    user: UserProfile;
    username: string;
    showUpload: boolean;
    hideUpload: () => void;
}

export const ProfilePicture: React.FC<profilePictureData> = ({onImageUpdate, onLoad, user, username, showUpload, hideUpload}) => {
    let animationSettings = { effect: "None" };
    let dialogInstance = useRef(null);
    let imageEditorInstance = useRef<ImageEditorComponent>(null);
    let imageUpload = useRef(null);
    let imageCanvas = useRef(null);
    let customImage = useRef(null);
    let profile = useRef(null);
    const [b64, setB64] = useState<string>('');
    const [showImage, setShowImage] = useState<boolean>(false);
    let imgSrc = "";

    const fileChanged = (args) => {
        const URL = window.URL;
        const url = URL.createObjectURL(args.target.files[0]);
        imageEditorInstance.current.open(url.toString());
        imageUpload.current.value = null;
        imgSrc = url.toString();
    };

    const handleImageLoaded = () => {
        if (imgSrc === "") {
            let ctx = imageCanvas.current.getContext("2d");
            imageCanvas.current.width =
            customImage.current.width < customImage.current.height
            ? customImage.current.width
            : customImage.current.height;
            imageCanvas.current.height = imageCanvas.current.width;
            ctx.drawImage(customImage.current, 0, 0, imageCanvas.current.width, imageCanvas.current.height);
            document.querySelector(".e-profile").classList.remove("e-hide");
        }
    };

    const dlgOpenButtonClick = () => {
        imageUpload.current.click();
    };

    const dlgResetButtonClick = () => {
        imageEditorInstance.current.reset();
    };

    const dlgRotateButtonClick = () => {
        imageEditorInstance.current.rotate(-90);
    };

    const closingModal = () => {
        imageEditorInstance.current?.clearSelection();
        imageEditorInstance.current?.reset();
        imageEditorInstance.current?.open(""); //This is the only way to clear the image from cache
        
        showUpload = false;
        hideUpload();
    }

    const dlgDoneButtonClick = () => {
        imageEditorInstance.current.crop();
        let croppedData : ImageData = imageEditorInstance.current.getImageData();
        let ctx : CanvasRenderingContext2D = imageCanvas.current.getContext("2d");
        let tempCanvas : HTMLCanvasElement = profile.current.appendChild(createElement("canvas"));
        let tempContext : CanvasRenderingContext2D = tempCanvas.getContext("2d")!;
        
        tempCanvas.width = croppedData.width;
        tempCanvas.height = croppedData.height;
        tempContext.putImageData(croppedData, 0, 0);
        
        let resizeCanvas : HTMLCanvasElement = document.createElement("canvas");
        let resizeContext : CanvasRenderingContext2D = resizeCanvas.getContext("2d")!;
        resizeCanvas.width = 128;
        resizeCanvas.height = 128;
        resizeContext.drawImage(tempCanvas, 0, 0, 128, 128);
        setB64(resizeCanvas.toDataURL('image/png'));
        
        ctx.clearRect(0, 0, imageCanvas.current.width, imageCanvas.current.height);
        ctx.drawImage(tempCanvas, 0, 0, imageCanvas.current.width, imageCanvas.current.height);
        tempCanvas.remove();

        imageCanvas.current.style.backgroundColor = "#fff";
        setShowImage(true);
        closingModal()
        dialogInstance.current.hide();
    }
    

    const userInitials = () => {
        var output: string = '';
        if (user?.firstName) {output = user.firstName.charAt(0).toUpperCase()}
        if (user?.lastName) {output += user.lastName.charAt(0).toUpperCase()}
        if (output === '' && username) {output = username.charAt(0).toUpperCase()}
        return output;
    };

    useEffect(() => {
        onImageUpdate(b64);
    }, [b64]);

    useEffect(() => {
        if (showUpload)
            editClicked();
    }, [showUpload])

    useEffect(() => {
        if (onLoad !== null && onLoad !== undefined) {
            setShowImage(true);
        }
    }, [onLoad])

    const defaultImage = () => {
        return onLoad == null ? usericon : onLoad;
    }

    const contentTemplate = () => {
        return (<ImageEditorComponent ref={imageEditorInstance} toolbar={[]} fileOpened={fileOpened}></ImageEditorComponent>);
    };
    
    const fileOpened = () => {
        imageEditorInstance.current.select("circle");
    };

    const editClicked = () => {
        dialogInstance.current.show();

        if (b64 === '') {
            imageEditorInstance.current.open(customImage.current.src + '?no=cors');
        } else {
            dlgResetButtonClick();
        }
    };

    let buttons = [
        {
            click: dlgOpenButtonClick,
            buttonModel: {
                content: "Choose Image",
                cssClass: "e-custom-img-btn e-img-custom-open",
            },
        },
        {
            click: dlgResetButtonClick,
            buttonModel: {
                content: "Reset",
                cssClass: "e-custom-img-btn e-img-custom-reset",
            },
        },
        {
            click: dlgRotateButtonClick,
            buttonModel: {
                content: "Rotate",
                cssClass: "e-custom-img-btn e-img-custom-rotate",
            },
        },
        {
            click: dlgDoneButtonClick,
            buttonModel: {
                content: "Apply",
                cssClass: "e-custom-img-btn e-img-custom-apply",
                isPrimary: true,
            },
        },
    ];

    return (<div className="control-pane">
                <div className="col-lg-12 control-section e-img-editor-profile">
                    <div className="d-flex flex-column align-items-center">
                        <div className="e-profile e-hide" ref={profile}>
                            <div className="e-custom-wrapper">
                                        <div className={(showImage ? 'hidden ' : '') + "e-avatar e-avatar-xlarge e-avatar-circle"}>
                                            {userInitials()}
                                        </div>
                                        <canvas id="img-canvas" className={(showImage) ? '' : 'hidden'} ref={imageCanvas}></canvas>
                                    <img alt="img" className="e-custom-img" id="custom-img" onLoad={handleImageLoaded} src={defaultImage()} ref={customImage}/>
                                <input type="file" id="img-upload" className="e-custom-file" onChange={fileChanged} ref={imageUpload}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="profile-dialog">
                    <DialogComponent 
                        id="profile-dialog" 
                        showCloseIcon={true} 
                        animationSettings={animationSettings} 
                        closeOnEscape={true} 
                        visible={false} 
                        width={"340px"} 
                        height={"420px"} 
                        ref={dialogInstance} 
                        target="#root" 
                        header="Edit Profile Image" 
                        buttons={buttons} 
                        content={contentTemplate} 
                        position={{ X: "center", Y: 100 }}
                        close={closingModal}
                        />
                </div>
            </div>);
    };
export default ProfilePicture;